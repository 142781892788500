import React, { useState } from "react";
import { Col, FormControl, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const SidebarMenu = () => {
  const [open, setOpen] = useState(false);

  let onSubmit = async (e) => {
    const data = {
      name: e.target[0].value,
      email: e.target[1].value,
      contact: e.target[2].value,
      company: e.target[3].value,
      message: e.target[4].value,
      title: "Query from ENERGY196",
    };
    e.preventDefault();
    try {
      let res = await fetch(
        "https://api.gfuturetech.com/api/contact/sendMail",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Submitted",
          text: "All the details are collected. We will contact you soon.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Some error occured. Please try again leter.",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="sidebar-menu mobile-only">
      <div
        className={!open ? "menu-bars" : "menu-bars open"}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>

      <div className={!open ? "sidebar-wrap" : "sidebar-wrap open"}>
        <div className="sidebar-menu-items">
          <div className="lap-menu w-100">
            {/* <a href="/news" className="sidebar-link">
              News & Insight
            </a> */}
            {/* <a href="/contact" className="sidebar-link">
              Contact
            </a> */}
            {/* <a href="/investor" className="sidebar-link">
              Investor
            </a> */}

            {/* <form onSubmit={onSubmit}>
              <Row className='gy-4'>
                <Col xl='6'>
                  <FormControl placeholder='Full Name*' required />
                </Col>
                <Col xl='6'>
                  <FormControl placeholder='Email*' type='email' required />
                </Col>
                <Col xl='6'>
                  <FormControl
                    placeholder='Phone Number*'
                    type='number'
                    required
                  />
                </Col>
                <Col xl='6'>
                  <FormControl placeholder='Subject*' required />
                </Col>
                <Col xs='12'>
                  <FormControl
                    placeholder='Messages*'
                    as='textarea'
                    rows={5}
                    required
                  />
                </Col>

                <Col xs='12'>
                  <button type='submit' className='btn submit-btn'>
                    Submit
                  </button>
                </Col>
              </Row>
            </form> */}
          </div>

          <div className="mobile-menu">
            <Link to="/about-us" className="sidebar-link">
              About Us
            </Link>

            {/* <Link to="/team" className="sidebar-link">
              Team
            </Link> */}

            <Link to="/technology" className="sidebar-link">
              Technology
            </Link>
            <Link to="/technology/orc" className="sidebar-link">
              Geothermal
            </Link>
            <Link to="/technology/geofuels" className="sidebar-link">
              Geofuels
            </Link>
            <Link to="/technology/hydrogen" className="sidebar-link">
              Hydrogen
            </Link>
            <Link to="/esg" className="sidebar-link">
              ESG
            </Link>
            <Link to="/portfolio-partners" className="sidebar-link">
              Portfolio Partners
            </Link>
            <Link to="/contact" className="sidebar-link">
              Contact
            </Link>
            {/* <Link to="/technology/rehm" className="sidebar-link">
              REHM
            </Link>
            <Link to="/technology/arcs" className="sidebar-link">
              ARCS
            </Link>
            <Link to="/technology/swers" className="sidebar-link">
              SWERS
            </Link>
            <Link
              to="/technology/flare-capture-and-monetization"
              className="sidebar-link"
            >
              Flare Recovery Technology
            </Link>
            <Link
              to="/technology/natural-gas-liquids-recovery"
              className="sidebar-link"
            >
              Natural Gas-Liquid Technology
            </Link>
            <Link
              to="/technology/increased-pipeline-capacity"
              className="sidebar-link"
            >
              Increased Pipeline Capacity
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
