import React from "react";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
import img from "../../assets/img/dryplant.png";
import { Col, Row } from "react-bootstrap";
import DetailBottomBanner from "./DetailBottomBanner";
import TechnologyBox from "../technologyBox";
import Bottom from "../home/Bottom";
import Loader from "../layout/loader";
import BinaryPlant from "./BinaryPlant";
import Slider from "react-slick";
import { faCentercode } from "@fortawesome/free-brands-svg-icons";

const Orc = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  return (
    <>
      <Loader />
      <InnerBanner title="Geothermal" />
      <PageHeading
        heading="In a dry steam power plant, steam is generated directly from the geothermal reservoir to run the turbines and that power is used through the generator to generate electricity. In this type of power plant, no separation is necessary because wells only produce steam"
        align="text-start"
      />

      <div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
          <Col xxl="6">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
                In this power plant, steam generates directly from the
                geothermal reservoir. This steam is directly supplied to the
                turbine and rotates the turbine. The turbine is in connection
                with a generator and produces electricity. After rotating the
                turbine, the steam goes through the condenser. The condenser
                converts the steam into the water known as condensate. This
                condensate is injected back through the injection well into the
                earth. These types of hydrothermal reservoirs are very rare.
              </p>
            </div>
          </Col>

          <Col xxl="5">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="200"
              className="overflow-hidden"
            >
              <img
                src={img}
                alt="ORGANIC RANKIN CYCLE (ORC) PLANT"
                className="plant-info-img"
              />
            </div>
          </Col>

          <Col>
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
                Dry steam reservoirs use the water in the earth's crust, which
                is heated by the mantle and released through vents in the form
                of steam. The dry steam power plant is suitable where the
                geothermal steam is not mixed with water. Production wells are
                drilled down to the aquifer and the superheated, pressurized
                steam (180°-350°C) is brought to the surface at high speeds, and
                passed through a steam turbine to generate electricity. In
                simple power plants, the low pressure steam output from the
                turbine is vented to the atmosphere, but more commonly, the
                steam is passed through a condenser to convert it to water. This
                improves the efficiency of the turbine and avoids the
                environmental problems caused from the direct release of steam
                into the atmosphere.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <h1 style={{ textAlign: "center", fontSize: "56px" }}>
        <b>Binary Power Plant</b>
      </h1>
      <BinaryPlant />

      {/* <div className='detail-bottom-banner'>
        <Slider {...settings}>
          <TechnologyBox
            link='/technology/scicat-technology'
            title='Technology'
            desc='Technology transfer the heat from geothermal hot water to another liquid.'
          />
          <TechnologyBox
            link='/technology/rehm'
            title='REHM TECHNOLOGY'
            desc='REHM uses electro magnetic resonance (EMR) to selectively transfer energy for the dissociation of methane from hydrate. '
          />
          <TechnologyBox
            link='/technology/arcs'
            title='ARCS TECHNOLOGY'
            desc='Arcs provides a means to use solar energy captured by the oceans for emission-free power generation. '
          />
          <TechnologyBox
            link='/technology/swers'
            title='SWERS TECHNOLOGY'
            desc='SWERS provides the ability to save water and recover waste energy in thermal power plants.'
          />
          <TechnologyBox
            link='/technology/flare-capture-and-monetization'
            title='Flare Recovery Technology'
            desc='Most efficient elimination and monetizing of onshore refinery, offshore platform, and land oil-well flaring.'
          />
          <TechnologyBox
            link='/technology/natural-gas-liquids-recovery'
            title='NATURAL GAS LIQUIDS RECOVERY'
            desc='Most efficient method of Natural Gas Liquids recovery and hydrocarbon condensates recovery.'
          />
          <TechnologyBox
            link='/technology/increased-pipeline-capacity'
            title='INCREASED PIPELINE CAPACITY'
            desc='Most efficient CAPEX and OPEX of oil and gas pipeline infrastructures from capacity optimization.'
          />
          <TechnologyBox
            link='/technology/hydrogen'
            title='Hydrogen'
            desc='Hydrogen energy is a source of renewable energy, produced by electrolysis.'
          />
        </Slider>
      </div> */}
    </>
  );
};

export default Orc;
