import React from "react";

const MenuItem = ({ link, name, openInNewTab }) => {
  return (
    <div className="menu-item">
      <a 
        href={link} 
        className="menu-link"
        target={openInNewTab ? "_blank" : undefined}
        rel={openInNewTab ? "noopener noreferrer" : undefined}
      >
        {name}
      </a>
    </div>
  );
};

export default MenuItem;
