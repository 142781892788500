import React from "react";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
import img from "../../assets/img/dryplant.png";
import { Col, Row } from "react-bootstrap";
import DetailBottomBanner from "./DetailBottomBanner";
import TechnologyBox from "../technologyBox";
import Bottom from "../home/Bottom";
import Loader from "../layout/loader";
import BinaryPlant from "./BinaryPlant";
import Slider from "react-slick";
import { faCentercode } from "@fortawesome/free-brands-svg-icons";
import icon1 from "../../assets/icons/icon1.png"; // Replace with your actual icon paths
import icon2 from "../../assets/icons/icon2.png";
import icon3 from "../../assets/icons/icon3.png";
import methanolImage from "../../assets/img/methanol.jpg";

const Geofuels = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  return (
    <>
      <Loader />
      <InnerBanner title="Geofuels" />
      <div style={{ padding: "200px 0", backgroundColor: "#fff" }}>
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <h2
            style={{
              textAlign: "center",
              marginBottom: "60px",
              fontSize: "36px",
              fontWeight: "bold",
            }}
          >
            The Concept
          </h2>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "30px" }}
          >
            {/* First Block */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#f1f5f9",
                borderRadius: "15px",
                padding: "30px",
                justifyContent: "center",
              }}
            >
              <img
                src={icon1}
                alt="Icon 1"
                style={{
                  width: "60px",
                  height: "60px",
                  marginRight: "30px",
                }}
              />
              <p
                style={{ marginBottom: 0, fontSize: "18px", lineHeight: "1.8" }}
              >
                Geothermal power offers a continuous and reliable source of
                emissions-free power, without the intermittency of wind and
                solar power.
              </p>
            </div>
            {/* Second Block */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#f1f5f9",
                borderRadius: "15px",
                padding: "30px",
                justifyContent: "center",
              }}
            >
              <img
                src={icon2}
                alt="Icon 2"
                style={{ width: "60px", height: "60px", marginRight: "30px" }}
              />
              <p
                style={{ marginBottom: 0, fontSize: "18px", lineHeight: "1.8" }}
              >
                The hot brines associated with sedimentary geothermal resources
                also include associated methane and CO2 which can be separated
                and used.
              </p>
            </div>
            {/* Third Block */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#f1f5f9",
                borderRadius: "15px",
                padding: "30px",
                justifyContent: "center",
              }}
            >
              <img
                src={icon3}
                alt="Icon 3"
                style={{ width: "60px", height: "60px", marginRight: "30px" }}
              />
              <p
                style={{ marginBottom: 0, fontSize: "18px", lineHeight: "1.8" }}
              >
                These are the precursors to producing low-carbon hydrogen and
                high-value, low-emissions e-methanol, green gasoline and
                sustainable aviation fuel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Geofuels;
