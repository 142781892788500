import React, { useEffect } from "react";
import Loader from "../layout/loader";
import About from "./About";
import Banner from "./Banner";
import Brief from "./Brief";
import Info from "./Info";
import Plants from "./Plants";
import Team from "./Team";
import Aos from "aos";
import Advantages from "./Advantages";

const Home = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Loader />
      <Banner />
      <div className="main-body">
        <About
          title="PIONEERING ENERGY SOLUTIONS"
          desc="ENERGY196 recognizes the complexity of the energy and is committed to investing in innovative energy solutions that address the pressing challenges of our time. Our mission at ENERGY196 is to drive progress and innovation in the energy industry through strategic investments in cutting-edge technologies and solutions. We are committed to advancing clean energy production, improving power storage capabilities, fostering the growth of renewable energy sources, and enabling sustainable mobility solutions. By investing in these areas, we aim to create a more efficient, sustainable, and secure energy future for generations to come. These are our key efforts:"
        />
        <div data-aos="fade-up" data-aos-duration="500">
          <Brief />
        </div>
        <About
          title="DEVELOPING ENERGY196 POWER ACROSS TEXAS AND THE UNITED STATES"
          desc="ENERGY196 has the ability to build all three types of ENERGY196 power plants."
        />
        <Plants />
        <Team />

        <Info />
        <Advantages />
      </div>
    </>
  );
};

export default Home;
