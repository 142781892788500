import React from "react";
import { Col, Row } from "react-bootstrap";
import InnerBanner from "../innerBanner";
import Loader from "../layout/loader";
import PageHeading from "../pageHeading";
import PrimaryBgHeading from "../primaryBgHeading";
import img from "../../assets/img/hydro1.png";
import img2 from "../../assets/img/hydro2.png";
import Slider from "react-slick";
import TechnologyBox from "../technologyBox";

const Hydrogen = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  return (
    <>
      <Loader />
      <InnerBanner title="Hydrogen" />
      <PageHeading
        heading={
          <>
            To produce Blue Hydrogen, the natural gas is split into hydrogen and
            carbon dioxide by either Steam Methane Reforming (SMR) or Auto
            Thermal Reforming (ATR). The CO<sub>2</sub> is then captured and
            stored. To achieve the electrolysis we need electricity, we need
            power. This process to make Blue Hydrogen is powered by renewable
            energy sources, such as wind or solar. That makes Blue Hydrogen the
            cleanest option – hydrogen from renewable energy sources without CO
            <sub>2</sub> as a by-product.'
          </>
        }
        align="text-start"
      />

      <div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
          <Col xxl="6">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
                The ‘capturing’ is done through a process called Carbon Capture
                Usage and Storage (CCUS). More about that in due course. To
                achieve the electrolysis we need electricity, we need power.
                This process to make green hydrogen is powered by renewable
                energy sources, such as wind or solar. That makes green hydrogen
                the cleanest option – hydrogen from renewable energy sources
                without CO<sub>2</sub> as a by-product.
              </p>
            </div>
          </Col>

          <Col xxl="5">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="200"
              className="overflow-hidden"
            >
              <img
                src={img}
                alt="Hydrogen"
                // className="responsive-image"
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "475px",
                }}
              />
            </div>
          </Col>

          <Col xs="12">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
                Hydrogen energy is a zero-carbon high efficiency green hydrogen
                source of power. This process to make green hydrogen is powered
                by renewable energy sources, such as wind or solar. As the
                electrolyzer produces hydrogen and oxygen, this mitigates the
                environmental impacts on the planet.
              </p>

              <p className="detail-info-text mt-4">
                As the world moves towards green energy, hydrogen is an
                increasingly attractive fuel with a great potential to replace
                electricity generated by fossil fuels. Hydrogen provides an
                alternative source of energy as an environmentally friendly
                alternative to traditional fossil fuels such as coal, oil, and
                natural gas. It’s also one of the most common elements found in
                the universe and has been used for thousands of years, resulting
                in numerous industrial processes.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <PrimaryBgHeading heading="Summary" />
      <h3 className="padding-x pt-5">
        Green hydrogen is when the energy used to power electrolysis comes from
        renewable sources like wind, water or solar. Blue hydrogen is hydrogen
        produced from natural gas with a process of steam methane reforming,
        where natural gas is mixed with very hot steam and a catalyst. A
        chemical reaction occurs creating hydrogen and carbon monoxide. Water is
        added to that mixture, turning the carbon monoxide into carbon dioxide
        and more hydrogen."
      </h3>

      <div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
          <Col xxl="5">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="200"
              className="overflow-hidden"
            >
              <img
                src={img2}
                alt="Hydrogen"
                // className="responsive-image"
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "475px",
                }}
              />
            </div>
          </Col>

          <Col xxl="6">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
                Blue hydrogen is produced when natural gas is burnt, with steam
                production and carbon dioxide captured and stored underground.
                This process is considered “carbon-neutral” because there are no
                methane emissions from the natural gas extraction or transport
                from the wellhead to air conditioners and furnaces.
              </p>
            </div>
          </Col>

          <Col xs="12">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
                Hydrogen Energy is an alternative energy source allowing
                self-sufficient communities to produce their own power and
                reduce their reliance on fossil fuels. The power is produced by
                splitting water into oxygen, or by burning natural gas to create
                steam for electrolysis, or electricity for sale. By tapping into
                renewable energy sources, the required thermal energy (DE) can
                be significantly reduced, resulting in a lower cost to the user
                measured in: $/kWh.
              </p>

              <p className="detail-info-text mt-4">
                Hydrogen energy is a source of renewable energy, produced by
                electrolysis. It's a clean-burning fuel with no carbon emissions
                and can be used in a wide range of ways to power vehicles,
                appliances and other industrial equipment.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      {/* <div className='detail-bottom-banner'>
        <Slider {...settings}>
          <TechnologyBox
            link='/technology/scicat-technology'
            title='Technology'
            desc='Technology transfer the heat from geothermal hot water to another liquid.'
          />
          <TechnologyBox
            link='/technology/rehm'
            title='REHM TECHNOLOGY'
            desc='REHM uses electro magnetic resonance (EMR) to selectively transfer energy for the dissociation of methane from hydrate. '
          />
          <TechnologyBox
            link='/technology/arcs'
            title='ARCS TECHNOLOGY'
            desc='Arcs provides a means to use solar energy captured by the oceans for emission-free power generation. '
          />
          <TechnologyBox
            link='/technology/swers'
            title='SWERS TECHNOLOGY'
            desc='SWERS provides the ability to save water and recover waste energy in thermal power plants.'
          />
          <TechnologyBox
            link='/technology/flare-capture-and-monetization'
            title='Flare Recovery Technology'
            desc='Most efficient elimination and monetizing of onshore refinery, offshore platform, and land oil-well flaring.'
          />
          <TechnologyBox
            link='/technology/natural-gas-liquids-recovery'
            title='NATURAL GAS LIQUIDS RECOVERY'
            desc='Most efficient method of Natural Gas Liquids recovery and hydrocarbon condensates recovery.'
          />
          <TechnologyBox
            link='/technology/increased-pipeline-capacity'
            title='INCREASED PIPELINE CAPACITY'
            desc='Most efficient CAPEX and OPEX of oil and gas pipeline infrastructures from capacity optimization.'
          />
          <TechnologyBox
            link='/technology/orc'
            title='ORGANIC RANKIN CYCLE (ORC) PLANT'
            desc='ORGANIC RANKIN CYCLE (ORC) PLANTs use steam directly from a geothermal reservoir to turn turbines'
          />
        </Slider>
      </div> */}
    </>
  );
};

export default Hydrogen;
