import React from 'react'
import PropTypes from 'prop-types'

const ContactDetail = props => {
  const isPhoneNumber = props.link.includes('(+1)') || props.link.match(/^\+?1?\d{10}$/)
  const href = isPhoneNumber ? `tel:${props.link.replace(/\D/g, '')}` : `mailto:${props.link}`

  return (
    <div className='contact-info'>
      <p className="contact-info-title">{props.title}</p>
      <p className={`contact-detail ${props.displayDetail}`}>{props.detail}</p>
      <a href={href} className={`contact-link ${props.display}`}>{props.link}</a>
    </div>
  )
}

ContactDetail.defaultProps = {
    display: "d-none",
    displayDetail: "d-block"
}

export default ContactDetail
