import React from "react";

const PlantSlide = (props) => {
  return (
    <div className="plant-slide">
      <div className="plant-content">
        <h2 className="plant-title">{props.title}</h2>
        <p className="plant-desc">{props.about}</p>
        <div className="plant-link">
          <a href={props.link} className="plant-btn" target="_blank">
            KNOW MORE {""}
            <svg
              width="16"
              height="12"
              viewBox="0 0 22 12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 6H21M21 6L16.102 1M21 6L16.102 11"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PlantSlide;

// import React from "react";

// const PlantSlide = (props) => {
//   return (
//     <div>
//       <div className="plant-slide">
//         <div className="plant-about">
//           <h2 className="plant-title">{props.title}</h2>
//           <p className="plant-desc">{props.about}</p>
//           <a href={props.link} className="text-btn">
//             Know More{" "}
//             <svg
//               width="22"
//               height="12"
//               viewBox="0 0 22 12"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M1 6H21M21 6L16.102 1M21 6L16.102 11"
//                 stroke="#31C46C"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//               />
//             </svg>
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PlantSlide;
