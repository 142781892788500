import React, { useState } from "react";
import MenuItem from "./MenuItem";
import SidebarMenu from "./SidebarMenu";
import logo from "../../../assets/img/logo.png";
import MenuWithSub, { SubMenu } from "./MenuWithSub";

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="header-wrap d-flex align-items-center justify-content-between">
      <div className="logo-wrap">
        <a href="/">
          <img src={logo} alt="" className="logo-img" width="180px" />
        </a>
      </div>

      <div className="menu-wrap d-flex align-items-center">
        <div
          className={
            !open
              ? "desk-menu d-flex align-items-center"
              : "desk-menu open d-flex align-items-center"
          }
        >
          <MenuItem link="/about-us" name="About Us" />

          <MenuWithSub link="/technology" name="Technology">
            {/* <MenuItem link="/technology/orc" name="Geothermal" /> */}
            {/* <SubMenu title="Geothermal">
              <MenuItem link="/technology/orc" name="ORC" />
              <MenuItem
                link="/technology/scicat-technology"
                name="Technology"
              />
            </SubMenu> */}

            {/* <SubMenu title="Greenammonia">
              <MenuItem link='/technology/rehm' name='REHM' />
              <MenuItem link='/technology/arcs' name='ARCS' />
              <MenuItem link='/technology/swers' name='SWERS' />
            </SubMenu> */}

            {/* <SubMenu title='Low Carbon Technology'>
              <MenuItem
                link='/technology/flare-capture-and-monetization'
                name='Flare Recovery Technology'
              />
              <MenuItem
                link='/technology/natural-gas-liquids-recovery'
                name='Natural Gas-Liquid Technology'
              />
              <MenuItem
                link='/technology/increased-pipeline-capacity'
                name='Increased Pipeline Capacity'
              />
            </SubMenu> */}
            <MenuItem
              link="https://geothermal.io/"
              name="Geothermal"
              openInNewTab={true}
            />
            <MenuItem
              link="https://geofuels.com/"
              name="Geofuels"
              openInNewTab={true}
            />

            <MenuItem
              link="https://www.greendatacenter.network/"
              name="Data Center"
              openInNewTab={true}
            />
            {/* <MenuItem link="/technology/hydrogen" name="Hydrogen" /> */}
          </MenuWithSub>
          {/* <MenuWithSub link="/technology" name="Technology Transition">
            <MenuItem link="/technology/transition" name="Energy Transition" />
            <MenuItem link="/technology/solution" name="Solution" />
          </MenuWithSub> */}

          <MenuItem link="/esg" name="ESG" />

          {/* <MenuItem link="/team" name="Team" /> */}

          <MenuItem link="/portfolio-partners" name="Portfolio Partners" />
          <MenuItem link="/contact" name="Contact Us" />
        </div>

        <SidebarMenu />
      </div>
    </div>
  );
};

export default Header;
