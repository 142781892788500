import React from "react";
import { Col, Row } from "react-bootstrap";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
import PrimaryBgHeading from "../primaryBgHeading";
import TechnologyBox from "../technologyBox";
import t1 from "../../assets/img/rehmtechnology.png";
import t2 from "../../assets/img/arcs1.png";
import t3 from "../../assets/img/swers1.png";
import t4 from "../../assets/img/flashsteam.png";
import t5 from "../../assets/img/drysteam.png";
import t6 from "../../assets/img/binary.png";
import Loader from "../layout/loader";

const Technology = () => {
  return (
    <>
      <Loader />
      <InnerBanner title="TECHNOLOGY" />
      <PageHeading
        heading="ENERGY196 PROVIDES THE BEST SOLUTION FOR YOUR ENERGY196 PLANT NEEDS"
        align="text-start"
      />

      <div className="small-info">
        <Row>
          <Col lg="3" className="d-none d-lg-block"></Col>
          <Col lg="9">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="small-info-text">
                The type of ENERGY196 system depends on the heat flow and
                geological setting of your plant's geographical location. They
                range from warm water systems where there is no fluid movement
                and no boiling, up to convecting two-phase systems with lots of
                boiling underground.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <PrimaryBgHeading heading="TECHNOLOGY WE FOCUS IN" />

      <div className="techno-box-wrap">
        <Row className="gy-4">
          <Col md="6" xl="4">
            <div data-aos-duration="700" data-aos="fade-right">
              <TechnologyBox
                // img={t5}
                // show="d-block"
                link="/technology/orc"
                title="ORGANIC RANKIN CYCLE (ORC) PLANT"
                desc="ORGANIC RANKIN CYCLE (ORC) PLANTs use steam directly from a geothermal reservoir to turn turbines"
              />
            </div>
          </Col>
          <Col md="6" xl="4">
            <div
              data-aos-duration="700"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <TechnologyBox
                // img={t6}
                // show="d-block"
                link="/technology/hydrogen"
                title="HYDROGEN"
                desc="Hydrogen energy is a zero-carbon high efficiency green hydrogen source of power."
              />
            </div>
          </Col>
          <Col md="6" xl="4">
            <div
              data-aos-duration="700"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <TechnologyBox
                // img={t6}
                // show="d-block"
                link="/technology/geofuels"
                title="GEOFUELS"
                desc="Through the use of baseload, zero-carbon geothermal power and methane with no upstream emissions, we produce a continuous stream of hydrogen with a carbon intensity of 0 kg CO2e /kg H2. By using CO2 already in the atmosphere, we are producing net-zero fuels without new fossil emissions."
              />
            </div>
          </Col>
          {/* <Col md="6" xl="4">
            <div
              data-aos-duration="700"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <TechnologyBox
                img={t6}
                show="d-block"
                link="/technology/scicat-technology"
                title="Technology"
                desc="Technology transfer the heat from geothermal hot water to another liquid."
              />
            </div>
          </Col> */}

          {/* <Col md='6' xl='4'>
            <div data-aos-duration='700' data-aos='fade-right'>
              <TechnologyBox
                img={t1}
                show='d-block'
                link='/technology/rehm'
                title='REHM TECHNOLOGY'
                desc='REHM uses electro magnetic resonance (EMR) to selectively transfer energy for the dissociation of methane from hydrate. '
              />
            </div>
          </Col> */}

          {/* <Col md='6' xl='4'>
            <div
              data-aos-duration='700'
              data-aos='fade-right'
              data-aos-delay='200'
            >
              <TechnologyBox
                img={t2}
                show='d-block'
                link='/technology/arcs'
                title='ARCS TECHNOLOGY'
                desc='Arcs provides a means to use solar energy captured by the oceans for emission-free power generation.'
              />
            </div>
          </Col> */}

          {/* <Col md="6" xl="4">
            <div
              data-aos-duration="700"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <TechnologyBox
                img={t3}
                show="d-block"
                link="/technology/swers"
                title="SWERS TECHNOLOGY"
                desc="SWERS provides the ability to save water and recover waste energy in thermal power plants."
              />
            </div>
          </Col> */}
        </Row>
      </div>
    </>
  );
};

export default Technology;
