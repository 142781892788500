import React from "react";
import Slider from "react-slick";
import PlantSlide from "../plantSlide";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      style={{
        position: "absolute",
        right: "-70px",
        top: "50%",
        transform: "translateY(-50%)",
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        background: "#E6FAF0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        zIndex: 10,
      }}
      onClick={onClick}
    >
      <svg
        width="14"
        height="26"
        viewBox="0 0 14 26"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1L13 13L1 25" stroke="#31C46C" strokeWidth="2" />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      style={{
        position: "absolute",
        left: "-70px",
        top: "50%",
        transform: "translateY(-50%)",
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        background: "#E6FAF0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        zIndex: 10,
      }}
      onClick={onClick}
    >
      <svg
        width="14"
        height="26"
        viewBox="0 0 14 26"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13 1L1 13L13 25" stroke="#31C46C" strokeWidth="2" />
      </svg>
    </div>
  );
}

const Plants = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <section className="plant-section">
      <Slider {...settings}>
        {/* <div className="plant-slide-wrap">
          <PlantSlide
            title="REHM Technology"
            about="The TransEnergy solution solves the prior challenges through patented Resonance Electromagnetic Hydrate Methane (REHM) technology."
            link="/technology/rehm"
          />
        </div>
        <div className="plant-slide-wrap">
          <PlantSlide
            title="Hydrogen"
            about="Hydrogen energy is a zero-carbon, high-efficiency green hydrogen source of power."
            link="/technology/hydrogen"
          />
        </div> */}
        <div className="plant-slide-wrap">
          <PlantSlide
            title="ORGANIC RANKIN CYCLE (ORC) PLANT"
            about="Organic Rankine Cycle (ORC) plants use steam directly from a geothermal reservoir to turn turbines."
            link="https://geothermal.io/"
          />
        </div>
        <div className="plant-slide-wrap">
          <PlantSlide
            title="Geofuels"
            about="Through the use of baseload, zero-carbon geothermal power and methane with no upstream emissions, we produce a continuous stream of hydrogen with a carbon intensity of 0 kg CO2e /kg H2."
            link="https://geofuels.com/"
          />
        </div>
        <div className="plant-slide-wrap">
          <PlantSlide
            title="Data Center"
            about=" Harnessing geothermal energy for sustainable and efficient data operations"
            link="https://www.greendatacenter.network/"
          />
        </div>
      </Slider>
    </section>
  );
};

export default Plants;

// import React from "react";
// import Slider from "react-slick";
// import PlantSlide from "../plantSlide";

// function SampleNextArrow(props) {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick}>
//       <svg
//         width="14"
//         height="26"
//         viewBox="0 0 14 26"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M1 1L13 13L1 25"
//           stroke="#77ABD2"
//           strokeWidth="2"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//       </svg>
//     </div>
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, onClick } = props;
//   return (
//     <div className={className} onClick={onClick}>
//       <svg
//         width="14"
//         height="26"
//         viewBox="0 0 14 26"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M13 25L1 13L13 0.999999"
//           stroke="#77ABD2"
//           strokeWidth="2"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//       </svg>
//     </div>
//   );
// }

// const Plants = () => {
//   const settings = {
//     dots: false,
//     infinite: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     speed: 1500,
//     autoplaySpeed: 3000,
//     nextArrow: <SampleNextArrow />,
//     prevArrow: <SamplePrevArrow />,
//     responsive: [
//       {
//         breakpoint: 560,
//         settings: {
//           arrows: false,
//           adaptiveHeight: true,
//         },
//       },
//     ],
//   };

//   return (
//     <section className="plant-section">
//       <Slider {...settings}>
//         <div className="plant-slide-wrap">
//           <PlantSlide
//             title="REHM Technology"
//             about="The TransEnergy solution solves the prior challenges through patented Resonance Electromagnetic Hydrate Methane (REHM) technology. This patented solution leverages existing concepts and technologies in new ways to solve the Methane Hydrate extraction challenge."
//             link="/technology/rehm"
//           />
//         </div>
//         <div className="plant-slide-wrap">
//           <PlantSlide
//             title="ARCS Technology"
//             about="ARCS utilizes the thermal gradient in deep oceanic waters for direct operation of power-generating turbines, using refrigerant-based thermosiphons."
//             link="/technology/arcs"
//           />
//         </div>
//         <div className="plant-slide-wrap">
//           <PlantSlide
//             title="SWERS Technology"
//             about="SWERS provides the ability to save water and recover waste energy in thermal power plants. Primary water loss is due to evaporation in the cooling tower."
//             link="/technology/swers"
//           />
//         </div>
//         <div className="plant-slide-wrap">
//           <PlantSlide
//             title="ORGANIC RANKIN CYCLE (ORC) PLANT"
//             about="Organic Rankine Cycle (ORC) plants use steam directly from a geothermal reservoir to turn turbines."
//             link="/technology/orc"
//           />
//         </div>
//         <div className="plant-slide-wrap">
//           <PlantSlide
//             title="Geofuels"
//             about="Through the use of baseload, zero-carbon geothermal power and methane with no upstream emissions, we produce a continuous stream of hydrogen with a carbon intensity of 0 kg CO2e /kg H2."
//             link="/technology/geofuels"
//           />
//         </div>
//         <div className="plant-slide-wrap">
//           <PlantSlide
//             title="Hydrogen"
//             about="Hydrogen energy is a zero-carbon, high-efficiency green hydrogen source of power."
//             link="/technology/hydrogen"
//           />
//         </div>
//       </Slider>
//     </section>
//   );
// };

// export default Plants;
