import React from "react";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
import img from "../../assets/img/binaryplant.png";
import { Col, Row } from "react-bootstrap";
import DetailBottomBanner from "./DetailBottomBanner";
import TechnologyBox from "../technologyBox";
import Bottom from "../home/Bottom";
import Loader from "../layout/loader";
import Slider from "react-slick";

const BinaryPlant = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  return (
    <>
      <Loader />
      {/* <InnerBanner title="Our Technology" /> */}
      <PageHeading
        heading="A binary power plant is a recent advancement in geothermal technology. It has made possible the financially viable production of electrical energy from geothermal resources lower than 150°C (302°F)."
        align="text-start"
      />

      <div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
          <Col xxl="6">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
                This type of power plant develops in locations where water
                reservoirs are available at a lower temperature of up to 100
                Fahrenheit. This hot water passed to a heat exchanger. This heat
                exchanger is with the secondary fluid (Butane and Pentane
                Hydrocarbon) has a lower boiling point than the primary hot
                water. Hot water from underground heats the secondary fluid, and
                it flashes into vapor at a lower temperature. This vapor drives
                the turbine, and the rest of the procedure is the same as in dry
                steam power plants.
              </p>
            </div>
          </Col>

          <Col xxl="5">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="200"
              className="overflow-hidden"
            >
              <img
                src={img}
                alt="ORGANIC RANKIN CYCLE (ORC) PLANT"
                className="plant-info-img"
              />
            </div>
          </Col>

          <Col>
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
                The binary geothermal power plant generates electrical energy
                from a secondary separated process. Preheating of the working
                fluid is involved and heat is lost upon contacting the
                geothermal fluid. A higher temperature range provide thermal
                stability of the working fluid while the lower temperatures are
                more feasible in terms of technoeconomic and financial factors.
                Further, the impacts of corrosion and scaling are not apparent
                at high temperature, as there is no contact between the power
                generation equipment and the geofluid. Under a conventional
                Rankine cycle, there is the functionality of the secondary fluid
                (working fluid) in the binary system, and the binary cycle is
                identified as the Organic Rankine Cycle (ORC) due to the organic
                nature of the working fluid.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      {/* <div className="detail-bottom-banner">
        <Slider {...settings}>
          <TechnologyBox
            link="/technology/flash-steam"
            title="FLASH STEAM PLANT"
            desc="Flash steam plants take high pressure hot water from deep inside the earth and convert it to drive generator turbines."
          />
          <TechnologyBox
            link="/technology/orc"
            title="ORGANIC RANKIN CYCLE (ORC) PLANT"
            desc="ORGANIC RANKIN CYCLE (ORC) PLANTs use steam directly from a geothermal reservoir to turn turbines"
          />
          <TechnologyBox
            link="/technology/rehm"
            title="REHM TECHNOLOGY"
            desc="REHM uses electro magnetic resonance (EMR) to selectively transfer energy for the dissociation of methane from hydrate. "
          />
          <TechnologyBox
            link="/technology/arcs"
            title="ARCS TECHNOLOGY"
            desc="Arcs provides a means to use solar energy captured by the oceans for emission-free power generation. "
          />
          <TechnologyBox
            link="/technology/swers"
            title="SWERS TECHNOLOGY"
            desc="SWERS provides the ability to save water and recover waste energy in thermal power plants."
          />
          <TechnologyBox
            link="/technology/flare-capture-and-monetization"
            title="Flare Recovery Technology"
            desc="Most efficient elimination and monetizing of onshore refinery, offshore platform, and land oil-well flaring."
          />
          <TechnologyBox
            link="/technology/natural-gas-liquids-recovery"
            title="NATURAL GAS LIQUIDS RECOVERY"
            desc="Most efficient method of Natural Gas Liquids recovery and hydrocarbon condensates recovery."
          />
          <TechnologyBox
            link="/technology/increased-pipeline-capacity"
            title="INCREASED PIPELINE CAPACITY"
            desc="Most efficient CAPEX and OPEX of oil and gas pipeline infrastructures from capacity optimization."
          />
          <TechnologyBox
            link="/technology/hydrogen"
            title="Hydrogen"
            desc="Hydrogen energy is a source of renewable energy, produced by electrolysis."
          />
        </Slider>
      </div> */}
    </>
  );
};

export default BinaryPlant;
